import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchPlanDetails,
  ctlFetchPlanDetails,
  getPlanDetailsCTL,
} from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { ACCOUNT_NUMBER } from "../../../../shared/utils/Constants";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import { v4 as uuidv4 } from "uuid";
import { getServiceAddressId } from "../../Utils/utils";

type planDetailsInitialStateType = {
  planDetails: any;
  loading: boolean;
  error: any;
  changePlanUrl: string;
  networkType: any;
  ctlPlanDetails: any;
  ctlLoading: boolean;
  ctlError: any;
  paperLessBilling: boolean;
  isAutoPayEnrolled: boolean;
};
const initialState: planDetailsInitialStateType = {
  planDetails: null,
  loading: false,
  error: null,
  changePlanUrl: "",
  networkType: null,
  ctlPlanDetails: null,
  ctlLoading: false,
  ctlError: null,
  paperLessBilling: false,
  isAutoPayEnrolled: false,
};

export const getPlanDetails = createAsyncThunk(
  "plan/details",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const serviceAddrId = getServiceAddressId(reduxStore?.user?.data?.addresses);
      const params = {
        [API_URLS?.planDetails_QA_Url]: billingAccountNumber,
      };

      const payload = {
        baseline: {
          //  transactionId: "09d5bd1a-3c6e-47ba-8a71-9ca83be5f3dc",
          transactionId: uuidv4().slice(0, 21),
          partnerId: "BS196123",
          salesCode: "20000660",
          partnerReferenceId: "",
          agentId: "",
          channel: "dotcom",
          siteId: headers?.siteId,
        },
        planDetailRequest: {
          accountNumber: billingAccountNumber,
          addressId: serviceAddrId,
          isMigrated: reduxStore?.user?.data?.migratedAccount && reduxStore?.user?.data?.migratedAccount?.toUpperCase() === "Y" ? "Y" : "N"
        },
      };

      if (
        headers?.siteId === "ctl" ||
        "century link" ||
        "qf" ||
        "quantum fiber"
      ) {
        const params = {
          "": [API_URLS?.planDetails_QA_Url],
        };
        const response = await ctlFetchPlanDetails(params, headers, payload);
        return response.data;
      } else {
        const response = await fetchPlanDetails(params, headers);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getCTLPlanDetails = createAsyncThunk(
  "ctl-plan/details",
  async (user: any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const serviceAddrId = getServiceAddressId(user?.addresses);
      const dataResult = await getPlanDetailsCTL(
        {
          "": `${API_URLS?.managePlandetailsCTL}`,
        },
        {
          baseline: {
            transactionId: uuidv4().slice(0, 21),
            partnerId: "BS196123",
            salesCode: "20000660",
            partnerReferenceId: "",
            agentId: "",
            channel: "dotcom",
            siteId: headers?.siteId,
          },
          productDetailRequest: {
            ban: user?.billingAccountNumber,
            addressId: serviceAddrId,
            isMigrated: user?.migratedAccount && user?.migratedAccount?.toUpperCase() === "Y" ? "Y" : "N"
          },
        },
        headers
      );
      return dataResult?.data || dataResult;
    } catch (error) {
      throw error;
    }
  }
);

const planDetailSlice = createSlice({
  name: "plan/Details",
  initialState,
  reducers: {
    clearPlanDetailSlice: (state) => {
      return initialState;
    },
    setChangePlanUrl: (state, action: PayloadAction<string>) => {
      state.changePlanUrl = action.payload;
    },
    setNetworkType: (state, action: PayloadAction<string>) => {
      state.networkType = action.payload;
    },
    setPaperLessBilling: (state, action: PayloadAction<boolean>) => {
      state.paperLessBilling = action.payload;
    },
    setAutoPayEnrolled: (state, action: PayloadAction<boolean>) => {
      state.isAutoPayEnrolled = action.payload;
    },
    planDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPlanDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPlanDetails.fulfilled, (state, action) => {
      state.planDetails = action.payload;
      state.paperLessBilling =
        action?.payload?.paperlessBilling === "ZY" ? true : false;
      state.isAutoPayEnrolled =
        action?.payload?.autoPayIndicator === "ZY" ? true : false;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPlanDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "plan details",
        action.error?.message,
        action?.type
      );
      state.planDetails = null;
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getCTLPlanDetails.pending, (state) => {
      state.ctlLoading = true;
    });
    builder.addCase(getCTLPlanDetails.fulfilled, (state, action) => {
      state.ctlPlanDetails = action.payload;
      //state.paperLessBilling  = (action?.payload?.paperlessBilling==="ZY")? true : false;
      // state.isAutoPayEnrolled = (action?.payload?.autoPayIndicator==="ZY")? true : false;
      state.ctlLoading = false;
      state.ctlError = null;
    });
    builder.addCase(getCTLPlanDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "plan details",
        action.error?.message,
        action?.type
      );
      state.ctlPlanDetails = null;
      state.ctlLoading = false;
      state.ctlError = action.error;
    });
  },
});

export const {
  clearPlanDetailSlice,
  setChangePlanUrl,
  setNetworkType,
  setAutoPayEnrolled,
  planDetailSliceSetInitialState,
} = planDetailSlice.actions;
export default planDetailSlice.reducer;
