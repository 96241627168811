import { ENVIRONMENT } from "./EnvConfig";

export const API_URL = "https://stoplight.io/mocks/brightspeed-apiverse/";
export const SMARTY_STREET_ADDRESS_URL =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup?key=117559863585036761&license=us-autocomplete-pro-cloud&search=";
export const LOCALHOST_SMARTY_STREET_ADDRESS_URL =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup?auth-id=075fdb94-1a3c-8165-b16f-d8428e11428e&auth-token=93d2qjdBKGgCLF3klTlV&search=";
export const VALIDATE_ADDRESSS_GET_LAT_LOG =
  "https://us-street.api.smartystreets.com";
export const FB_FIELDS = "id,email,name,first_name,middle_name,last_name";
export const ACCOUNT_NUMBER = "A00004763";
export const INVOICE_NUMBER = "NV00000849";
export const ACCOUNT_KEY = "8ac698a881cda5d60181d045bc4753d9";
export const ACCOUNT_MANAGE_ID = "77611723";

export const API_KEY = "bltae235e99a6dd6f85";
export const API_KEY_PROD = "blt63e9a2c6573b53ee";
export const ACCESS_TOKEN = "cs3417308ce78752a550fd6143";
export const ACCESS_TOKEN_PROD = "cs9d381ae779fc9019169c749c";
export const CHANNEL_ID = "mobile-acm";

export const PAYMENT_IFO_ID = "79847062";
const IAM_GET_USER_DETAILS = `?$select=userPrincipalName&$filter=mail%20eq%20'EMAIL' AND ${
  isProd() || isUAT()
    ? "extension_42054e1351c040b08b185b58c192f49e_pwResetEmailId"
    : "extension_a5021af605b141d78e63d3338ade2884_pwResetEmailId"
} eq 'ACCID'`;

function isProd() {
  return (
    process.env.REACT_APP_ENV === "production" ||
    process.env.NODE_ENV === ENVIRONMENT.production
  );
}

function isUAT() {
  return (
    process.env.REACT_APP_ENV === "uat" ||
    process.env.NODE_ENV === ENVIRONMENT.uat
  );
}

export const ACCOUNT_CONTENT = {
  page: "account_settings_page",
  key: isProd() ? "blt4be7bded6a4c64fd" : "blt8894c30e6af6cf57",
};
export const HELP_SUPPORT_CONTENT = {
  page: "help_and_support_content",
  key: isProd() ? "blt9b1bbae3acc05448" : "blt3ba11b02507b7615",
};
export const VACATION_PAUSE_SERVICE = {
  page: "vacation_pause_service",
  key: isProd() ? "bltd3e22bf0d010aa7a" : "blt6eb2ad80da6a4ed9",
};
export const NON_PAY_SUSPEND = {
  page: "non_pay_suspend",
  key: isProd() ? "blt529f1450105402bb" : "blt4e104463d48abbfa",
};
export const CHANGE_PLAN = {
  page: "change_my_plan",
  key: isProd() ? "bltd05c82f28c9e43d5" : "blt449b691704b95b0f",
};
export const HOME_CONTENT = {
  page: "account_management_home_page",
  key: isProd() ? "blt4a5200fbf350e9af" : "blt71a0570d2338effd",
};
export const APPLY_ACP_MODAL_CONTENT = {
  page: "account_management_apply_acp_modal",
  key: isProd() ? "bltce4548c7ac94df74" : "bltd944f0cccb9be21b",
};
export const ACP_FORM_CONTENT = {
  page: "account_management_acp_signup",
  key: isProd() ? "blteb3d92aa0ba6bba5" : "blt4b34ec7fb4070f10",
};
export const PAYMENT_CONTENT = {
  page: "payments_page",
  key: isProd() ? "bltdec64e72836e0a31" : "blt6587e18a21a64c3e",
};
export const APPOINTMENT_CONTENT = {
  page: "appointment_page",
  key: isProd() ? "blt1270efbc9ca0b15b" : "blta3a865a924a6849c",
};
export const LOGIN_CONTENT = {
  page: "login_content",
  key: isProd() ? "blt040d63a05165d301" : "blt6c30266b823f44a7",
};
export const LOGIN_HEADER_CONTENT = {
  page: "login_header_content",
  key: isProd() ? "blt924fde0c54ebb041" : "bltc2bc0630347993e8",
};

export const NEW_LOGIN_HEADER_CONTENT = {
  page: "am_login_header",
  key: isProd() ? "blteffc804436ba874d" : "bltad5cb3179f224550",
};

export const FOOTER_CONTENT = {
  page: "footer_account_management",
  key: isProd() ? "blt65c8a8a2a6d0e7a6" : "blt348cc772a200ec13",
};

export const NEW_LOGIN_FOOTER_CONTENT = {
  page: "am_login_footer",
  key: isProd() ? "blt2bcefb8727b78417" : "blt4a8ddb59690d2632",
};

export const FOOTER_HOME_CONTENT = {
  page: "am_home_footer",
  key: isProd() ? "bltda56faaf2b56a237" : "bltf251b96e027182af",
};
export const LAYOUT_CONTENT = {
  page: "layout_page",
  key: isProd() ? "bltede987a3555edef6" : "blt90940f7d449591d0",
};

export const LOGIN_FORGET_PAGE_CONTENT = {
  page: "account_management_login_forget",
  key: isProd() ? "blt7c6f4450343311cc" : "blt7007907f00fcf0da",
};

export const SIGNUP_RESET_PAGE_CONTENT = {
  page: "account_management_signup_reset",
  key: isProd() ? "bltc9de9b7208ef6247" : "blt642778d68fdbe530",
};

export const QUICK_BILL_PAYMENT_CONTENT = {
  page: "quick_bill_payments",
  key: isProd ? "bltc60712bf77a83e48" : "blt36eebdff581bac4f",
};

export const contentApiHeader = {
  api_key: !isProd() ? API_KEY : API_KEY_PROD,
  access_token: !isProd() ? ACCESS_TOKEN : ACCESS_TOKEN_PROD,
};
export const NETWORK_SETTINGS_CONTENT = {
  page: "am_network_settings",
  key: isProd() ? "blt1acd156ef18c4df7" : "blt40ee2cf6fbc2c181",
};
export const AMS_Cridentials = {
  staging: {
    amsAuthCredentitals: {
      username: "8yn5qUQyuqv98oqA5K1hgzHmttjiCGRg",
      password: "Cfir5imhfpNEdTsT",
    },
  },
  QA: {
    amsAuthCredentitals: {
      username: "NXP5dlMC6eTYM0wPGq3aJBXIuFPir28o",
      password: "6hf4l9Mw1M4H0tWC",
    },
  },
  uat: {
    amsAuthCredentitals: {
      username: "xaqrETFeDAt1KP4iyMlR5cSG7ZZtqy3e",
      password: "Dq80IzGkPBRX4QFB",
    },
  },
  production: {
    amsAuthCredentitals: {
      username: "xaqrETFeDAt1KP4iyMlR5cSG7ZZtqy3e",
      password: "Dq80IzGkPBRX4QFB",
    },
  },
};

export const watsonSearchEnviromentKeys = {
  base_url:
    "https://api.us-east.discovery.watson.cloud.ibm.com/instances/e19b336c-e6f2-4450-9afc-ed6829c84251",
  environment_id: "333e1bdb-87f4-4e80-b0e5-58b828f8f49b",
  collection_id: "3443f1b4-4bd0-405e-b098-e9ff06552b51",
  query_version: "2023-03-31",
  returnType:
    "extracted_metadata.file_type,extracted_metadata.title,metadata.source.url,text",
  token: "YXBpa2V5OjBSR0J4dTJHRlhCemxwaXd5WDctVjdCWUs4bk9Sa0dZOWpVSjhnNmZsN1Az",
  tokenType: "Basic",
};

export const contentApiParams = (param) => {
  return {
    [param.page]: `entries/${param.key}${
      !isProd() ? "?environment=development" : "?environment=production"
    }`,
  };
};

export const getIAM_UserParamsByEmailAndAccountId = (email, accountId) => {
  return IAM_GET_USER_DETAILS?.replace("EMAIL", email)?.replace(
    "ACCID",
    accountId
  );
};

// in web we are using this object for setting refreshToken
export const webRefreshTokenRedux = {};

export const EC_DW_LOGGING_STEPS = {
  login_attempts: "Login Attempted",
  login_success: "Login Success",
  login_failed: "Login Failed",
  username_notfound: "Username or principalName not found",
  token_unavailable: "get iam token unavailable",
  reschedule_attempt: "Reschedule appointment Attempt",
  submit_reschedule: "Submit Reschedule Appointment",
  list_pay_methods: "List Payment Methods",
  change_password: "Change Password",
  account_info_update: "Account info update",
  update_pay_info: "Update Payment Info",
  set_pass_attempt: "Set Password Attempted",
  set_pass_success: "Set Password Success",
  set_pass_failed: "Set Password Failed",
  forgot_pass_attempt: "Forgot Password Attempted",
  sent_otp: "Send OTP",
  sent_otp_for_sign_up: "send-otp-sign-up",
  sent_otp_for_captcha: "send-otp-captcha",
  sent_otp_for_forget_pass: "send-otp-forget-password",
  validate_otp: "Validate OTP",
  validate_otp_for_sign_up: "Validate-otp-sign-up",
  validate_otp_for_captcha: "Validate-otp-captcha",
  validate_otp_For_forget_pass: "Validate-otp-forget-password",
  forgot_pass_success: "Forgot Password Success",
  forgot_pass_failed: "Forgot Password Failed",
  account_not_enabled: "Account Not Enabled",
  account_enabled: "Account Enabled",
  add_primary_payment: "Add Primary Payment",
  add_payment_method: "Add Payment method",
  deleted_payment_method: "Payment method deleted",
  view_statement: "view-statement-clicked",
  view_pdf: "view-pdf-clicked",
  message_tech: "Message Tech",
  add_to_calander: "Add to Calendar",
  upcomming_appointment: "Upcoming Appointment",
  forgot_username_attempt: "Forgot Username Attempted",
  forgot_username_success: "Forgot Username Success",
  forgot_username_failed: "Forgot Username Failed",
  create_account: "Create Account",
  create_acc_set_pass: "Create Acc Set Password",
  create_account_succcess: "Create Account Success",
  create_account_failed: "Create Account Failed",
  pause_service_date_selected: "pause_service_date_selected",
  pause_service_date_defaulted: "pause_service_date_defaulted",
  resume_service_date_selected: "resume_service_date_selected",
  pause_service_cancel_clicked: "pause_service_cancel_clicked",
  pause_service_goBack_clicked: "pause_service_goBack_clicked",
  pause_service_attempted: "pause_service_attempted",
  pause_service_success: "pause_service_success",
  pause_service_failure: "pause_service_failure",
  pause_service_clicked: "pause_service_clicked",
  pause_service_update_clicked: "pause_service_update_clicked",
  pause_service_date_defaulted: "pause_service_date_defaulted",
  resume_service_date_selected: "resume_service_date_selected",
  change_plan_attempted: "Change Plan Attempted",
  change_plan_falied: "Change Plan Failed",
  change_plan_success: "Change Plan Success",
  change_plan_fiber: "Change Plan Fiber Attempted",
  change_plan_voice_add: "Change Plan Add Voice Attempted",
  change_plan_voice_cancel: "Change Plan cancel Voice Attempted",
  change_plan_fiber_success: "Change Plan Fiber Success",
  change_plan_voice_add_success: "Change Plan Add Voice Success",
  change_plan_voice_cancel_success: "Change Plan cancel Voice Success",
  pay_now_attempted: "Pay Now Attempted",
  pay_now_jeopardy_attempted: "Pay Now Jeopardy Attempted",
  pay_now_success: "Pay Now Success",
  pay_now_failed: "Pay Now Failed",
  sign_in_attempt: "Sign In Attempted",
  sign_up_attempt: "Sign Up Attempted",
  sign_up_success: "Sign Up Success",
  sign_up_failed: "Sign Up Failed",
  uni_login_address_attempt: "unilogin_attempt_address",
  uni_login_phoneNumber_attempt: "unilogin_attempt_phone",
  uni_login_address_success: "unilogin_success_address",
  uni_login_phoneNumber_success: "unilogin_success_phone",
  network_settings_page_accessed: "Network Settings Page Accessed",
  network_wifi_name_update_success: "Network WiFi Name Update Success",
  network_wifi_name_update_failed: "Network WiFi Name Update Failed",
  network_wifi_password_update_success: "Network WiFi Password Update Success",
  network_wifi_password_update_failed: "Network WiFi Password Update Failed",
  network_modem_restart_success: "Network Modem Restart Success",
  network_modem_restart_failed: "Network Modem Restart Failed",
  account_unlocked: "Account Unlocked Success",
  account_locked: "Account Locked Success",
  account_switched_success: "User switched to other account success",
  multi_account_login: "Multi accounts user has logged in",
  Add_account_attempted: "Add account attempted",
  forget_acc_num_attempted: "forget account number attempted",
  forget_acc_num_email_success: "Forget account number email success",
  forget_acc_num_email_fail: "Forget account number email failed",
  forget_acc_num_try_again_attempt: "Forget account number Try Again attempted",
  add_acc_found_success: "Add account found successfully",
  add_acc_found_fail: "Add account could not be found",
  add_acc_OTP_success_with_email:
    "Add account OTP request sent successfully with email",
  add_acc_OTP_success_with_phone:
    "Add account OTP request sent successfully with phone",
  add_acc_OTP_resend_success:"Add account OTP resend successfully",  
  add_acc_OTP_resend_fail:"Add account resend OTP fail",
  add_acc_validated_OTP_attempted:"Add account OTP Verification code attempted",
  add_acc_validated_OTP_success:"Add account OTP Verification code Success",
  cancle_cta_add_acc_comm_not_avaliabe_page: "Cancel clicked on add account communication method not available",
  try_again_add_acc_comm_not_avaliabe_page:"Try again attempted on communication method not available",
  cancle_cta_add_acc_OTP_verification: "Cancel clicked on add account OTP verification page",
  validated_OTP_fail: "Invalid OTP entered",
  validated_OTP_success: "OTP validated successfully",
  registered_account_popup_Success: "Already registered Account popup Success",
  try_again_attempt_registered_Acc_popup:
    "Try Again attempted on registered Account popup",
  registered_account_popup_Cancle: "Cancle on registered Account popup",
  cancle_cta_add_acc_comm_avaliabe_page:"Canceled on communication method",
  add_acc_success: "Add Account successfully",
  add_acc_fail: "Add Account fail",
  add_acc_validated_OTP_fail: "Add account Invalid OTP entered",
  navigate_to_home: "Add Account success page navigate to dashboard successfully",
  cancle_cta_add_acc: "Cancel clicked on add account",
  cancle_cta_forget_acc_popup: "Cancel clicked on add account forget account popup",
  autoPay_enroll_cancle: "cancle clicked on enroll autoPay",
  autoPay_enroll_sucess: "AutoPay enroll success",
  autoPay_enroll_fail: "AutoPay enroll fail",
  paperless_billing_accept: "Paperless billing accepted",
  paperless_billing_decline:"Paperless billing declined",
  remove_autoPay_sucess: "AutoPay remove success",
  remove_autoPay_fail: "AutoPay remove fail",
  remove_autoPay_cancle: "cancle clicked on remove autoPay popup",
  update_autoPay_back: "Back clicked on manage autoPay",
  update_PPM_confirm_back: "Back clicked on confirm primary payment method popup",
  update_PPM_confirm_continue: "Continue to confirm primary payment method popup",
  update_PPM_sucess: "Update primary payment method successfully",
  update_PPM_fail: "Update primary payment method faild",
  CVV_cancle: "Cancle clicked on CVV popup",
  disable_AutoPay_cancel: "Cancle clicked on disable AutoPay popup",
  disable_AutoPay_sucess: "AutoPay disable successfully",
  disable_AutoPay_fail: "AutoPay disable faild",
  deleted_payment_method_cancel: "AutoPay cancle clicked on delete payment method popup",
  deleted_payment_method_sucess: "AutoPay payment method deleted successfully",
  deleted_payment_method_fail: "AutoPay payment method deleted faild",
  android_store_link: "Android Google Play Link Clicked",
  ios_store_link: "Apple Store Link Clicked",
  payment_successful: "step2 of 2, submit payment processed successfuly",
  payment_failed: "step2 of 2, submit payment failed",
  referalClicked: "Referral link clicked",
  register_acc_email_success:"Account register notification email sent successfully",
  
  
  agree_and_continue: "Step 1 of 2, agree and continue button is clicked",
  /** QBP steps */
  qbp_stepped_in: "qbp stepped in",
  qbp_back_step1: "qbp step-1 Back link clicked",
  qbp_cancel_step1: "qbp step-1 Cancel button clicked",
  qbp_select_bank_continue_step2: "qbp Bank selected & continue step2",
  qbp_select_credit_continue_step2: "qbp Credit selected & continue step2",

  qbp_new_bank_cta: "qbp New bank form link clicked",
  qbp_edit_bank_cta: "qbp Edit bank link clicked",
  qbp_delete_bank_cta: "qbp Delete bank button clicked",
  
  qbp_new_cc_cta: "qbp New credit form link clicked",
  qbp_edit_cc_cta: "qbp Edit credit link clicked",
  qbp_delete_cc_cta: "qbp Delete credit button clicked",

  qbp_cancel_form: 'qbp Cancel form',

  qbp_add_bank_method: 'qbp Bank method add Sucess',
  qbp_add_cc_method: 'qbp Add a credit method',
  qbp_add_bank_method_successs: 'qbp Bank method add Sucess',
  qbp_add_bank_method_failed: 'qbp Bank method add Failed',
  qbp_add_credit_method_successs: 'qbp Credit method add Sucess',
  qbp_add_credit_method_failed: 'qbp Credit method add Failed',

  qbp_update_bank_method: 'qbp Update a bank method',
  qbp_update_credit_method: 'qbp Update a credit method',
  qbp_update_bank_method_success: 'qbp Update a bank method Sucess',
  qbp_update_bank_method_failed: 'qbp Update a bank method Failed',
  qbp_update_credit_method_success: 'qbp Update a credit method Sucess',
  qbp_update_credit_method_failed: 'qbp Update a credit method Failed',

  qbp_back_step2: "qbp Step-2 Back link clicked",
  qbp_cancel_step2: "qbp step-2 Cancel button clicked",
  qbp_attempted: 'qbp attempted',
  qbp_success: 'qbp payment submit success',
  qbp_failed: 'qbp payment submit failed',
  qbp_close_confirm_cta: 'qbp/unauth Close confirm modal',

  unauthqbp_signin_cta: 'unauthqbp AM Siginin button clicked',
  unauthqbp_stepped_in: 'unauthqbp stepped in',
  unauthqbp_back_step1: "unauthqbp step-1 Back link clicked",
  unauthqbp_cancel_step1: "unauthqbp step-1 Cancel button clicked",

  unauthqbp_add_bank_continue_step2: "unauthqbp step-1 Add bank and continue",
  unauthqbp_add_cc_continue_step2: "unauthqbp step-1 Add credit and continue",
  unauthqbp_add_bank_method_success: 'unauthqbp Update a bank method Sucess',
  unauthqbp_add_bank_method_failed: 'unauthqbp Bank method add Failed',
  unauthqbp_add_credit_method_successs: 'unauthqbp Credit method add Sucess',
  unauthqbp_add_credit_method_failed: 'unauthqbp Credit method add Failed',

  unauthqbp_back_step2: "unauthqbp Step-2 Back link clicked",
  unauthqbp_cancel_step2: "unauthqbp step-2 Cancel button clicked",
  unauthqbp_attempted: 'unauthqbp attempted',
  unauthqbp_success: 'unauthqbp payment submit success',
  unauthqbp_failed: 'unauthqbp payment submit failed',
  
  select_unauth_qbp_bank_method: 'Select unauth qbp bank method',
  update_qbp_bank_method: 'Update qbp bank method',
  select_unauth_qbp_credit_method: 'Select unauth QBP credit method',
  update_qbp_credit_method: 'Update QBP credit method',


  Pa_ineligible_landing_already_in_pa_modal:"Ineligible for PA due to existing entries.",
  Pa_ineligible_landing_payment_issue_with_pa_modal: "PA ineligible due to payment issues.",
  Pa_ineligible_landing_prepay_account_modal:"PA ineligible due to account type being a prepay account.",
  Pa_ineligible_landing_INACTIVE_account_modal: "PA ineligible due to the user being inactive.",
  Pa_back_step1: "PA step-1 Back link clicked",
  Pa_continue_step1: "PA step-1 continue cta clicked to step2",
  pa_continue_step1: "PA Step1 continue cta clicked to step2",
  pa_autopay_remove_confiramtion: "PA step1 autopay removed and continued to step2",
  Pa_cancel_step1: "PA step1 cancel cta clicked to landing page",
  pa_payment_option_select_setp1: "PA step1 selected payment option: ",
  Pa_back_step2: "PA step-2 Back link clicked",
  Pa_term_and_condition_step2:"PA step2 T&C checked",
  Pa_cancel_step2: "PA step2 cancel cta clicked to landing page",
  Pa_agree_and_continue_step2: "PA step-2 agree and continue clicked to step3",
  Pa_add_payment_method_success:"PA step-2 payment method is successfully added",
  Pa_add_payment_method_failure: "PA step-2 payment method adding failed",
  Pa_edit_payment_method_success:"PA step-2 payment method is successfully edited",
  Pa_edit_payment_method_failure: "PA step-2 payment method editing failed",
  Pa_cancel_form:"Pa step-2 cancel form clicked",
  Pa_login_failed:"PA log in failed",
  Pa_quick_bill_on_payment_Arrangment: "PA step-3 QBP on PA success",
  Pa_quick_bill_on_payment_Arrangment_failur: "PA step-3 QBP on PA failed",
  Pa_submit_payment_arrangment: "PA step-3 submission successfuly",
  Pa_submit_payment_arrangment_failed: "PA step-3 submission failed",
  Pa_links_clicked:"On PA links clicked",
  Pa_back_step3: "PA step-3 Back link clicked",
  nonAuth_otp_validation_success: "NonAuth otp validation is successful",
  nonAuth_otp_validation_failure: "NonAuth otp validation failed",
};

export const notificationType = {
  creditCard: "CreditCard",
  buryCable: "BuryCable",
  acpBanner: "ACPBanner",
  paymentExpired: "suspend",
  vacationSuspend: "VacationSuspend",
  roadBore: "RoadBore",
  userSuspended: "Suspend",
  nopaymentmethod: "nopaymentmethod",
  changePlanPending: "changePlanPending",
  
};

export const companyNameofQF = [
  "QUANTUM FIBER",
  "QF",
  "QF-II",
  "QUANTUM FIBER II",
  "CENTURY LINK",
  "CTL",
];

export const CONST_VALUES = {
  account_lock: "ACCOUNT LOCKED",
  customer_id_key:
    isProd() || isUAT()
      ? "extension_42054e1351c040b08b185b58c192f49e_CustomerID"
      : "extension_a5021af605b141d78e63d3338ade2884_CustomerID",
  account_enabled_message_key: `extension_${
    isProd() || isUAT()
      ? `42054e1351c040b08b185b58c192f49e`
      : `a5021af605b141d78e63d3338ade2884`
  }_AccountEnabled_Message`,
  emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  new_customer: "CTL",
  old_customer: "CENTURY LINK",
  lastLoginBanKey:(isProd() || isUAT())
  ? `extension_42054e1351c040b08b185b58c192f49e_pwResetEmailId`
  : `extension_a5021af605b141d78e63d3338ade2884_pwResetEmailId`,
};
export const publicKeySpeedPay = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvP/fwJOkay71xndL11V9ZizemWrZ4Z3YcFlNpZ5C0xu/5f/60LqpO/4916QSjsaGrzNmt/IqRwGVsBfxnYOxioa7MC3IynVjK43fE8enz0lA7F7rX5tsNbhrfNooGdhisNmMJFOWWaCt1/wJy6sHGc8mBxxnsOnW4M4kjnwSaxGkwKn53cBk3KzpsGHb8mTzpX05vYwjoSp7k6uER3HtwDfLCOxCEqjraA6vOMo8S7TNVY54iywsQkNkOymCVwOlDqVua9OUVjltNIYfIwrpsl/GMS0tm1+p3V4au3rfA9sWMfuJFyyaJ3OdvCzAwp3G2f5cH+lTXvQWC2aTV7YNjQIDAQAB`;
